import {propOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useToggledQuery from '../../useToggledQuery';
import {SOFT_CREDIT_CHECKS_BY_PROJECT_ID} from '../../../queries';

export default function useSoftCreditChecksByProjectId({
  onCompleted = noop,
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const {data, error, loading, fetch} = useToggledQuery({
    query: SOFT_CREDIT_CHECKS_BY_PROJECT_ID,
    lazy,
    options: {
      onCompleted,
      fetchPolicy,
      ...options,
    },
  });
  const softCreditChecks = propOr([], 'softCreditChecksByProjectId', data);
  return {softCreditChecks, loading, error, fetch};
}
