import React, {useState} from 'react';

import {useHistory, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';

import {Button} from './styled';

const LinkButton = ({children, tab}) => {
  const [hover, setHover] = useState(false);
  const {pathname} = useLocation();
  const history = useHistory();

  const getActive = () => {
    const isConditionsActive = pathname.includes('/underwriting-conditions');

    return tab === 'conditions' ? isConditionsActive : !isConditionsActive;
  };

  const active = getActive();

  const onClick = () => {
    if (tab === 'pipeline') {
      return history.push('/borrowers-pipeline');
    }

    history.push('/underwriting-conditions');
  };

  return (
    <Button
      css={{width: 56}}
      onClick={() => onClick(tab)}
      active={active}
      hover={hover}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      {children({active, hover})}
    </Button>
  );
};

LinkButton.propTypes = {
  children: PropTypes.func.isRequired,
  tab: PropTypes.string,
};

export default LinkButton;
