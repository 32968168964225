import styled from '@emotion/styled';

import {Text} from '@renofi/components-internal';

export const ItemsBarTabFileName = styled(Text)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  userSelect: 'none',
});
