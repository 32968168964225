import React from 'react';

import {Drawer, Flex, Tooltip} from '@renofi/components-internal';

import Pipeline from './icons/Pipeline';
import Conditions from './icons/Conditions';
import LinkButton from './components/LinkButton';

const ICONS = {
  pipeline: Pipeline,
  conditions: Conditions,
};

const TABS = [
  {label: 'Pipeline', value: 'pipeline'},
  {label: 'Conditions', value: 'conditions'},
];

const LeftSideNavbar = () => {
  return (
    <Drawer
      contentCss={{overflow: 'auto'}}
      css={{zIndex: 2}}
      min={20}
      max={80}
      open
      sticky
      show
      top={130}>
      <Flex flexDirection="column" p={3} css={{gap: 16}}>
        {TABS.map(({label, value}) => {
          const Icon = ICONS[value];

          return (
            <Tooltip
              elementCss={{width: 56}}
              tag="div"
              key={value}
              content={<div>{label}</div>}>
              <LinkButton tab={value} key={value}>
                {() => <Icon />}
              </LinkButton>
            </Tooltip>
          );
        })}
      </Flex>
    </Drawer>
  );
};

export default LeftSideNavbar;
