import {find, isNil, pipe, prop, propEq, propOr} from 'ramda';
import {useLocation, useParams} from 'react-router-dom';
import {withViewerLayout} from 'api/hoc';
import TASK_STATUSES from 'lib/task/taskStatuses';

import {branch, compose, renderNothing, withProps} from '@renofi/recompose/src';
import {useProject} from '@renofi/graphql';

import {withFacetCurrentTask} from '../../hoc';
import ViewerContext from '../../context';

import Component from './Component';

const areAllCompleted = propEq('status', TASK_STATUSES.COMPLETED);

/**
 * @description Branching to handle case where ViewerV2 is used
 * @todo can kill it off when we decouple from HOC completely.
 */
export default compose(
  branch(
    () => {
      const {pathname} = useLocation();
      const isV2Viewer = pathname.includes('viewer-v2');
      return isV2Viewer;
    },
    withProps(() => {
      const {itemId, projectId} = useParams();
      const {project} = useProject({variables: {id: projectId}});
      const tasks =
        project?.tasks.filter(({facet}) => facet === 'eligibility') || [];

      return {
        complete: tasks.every(areAllCompleted),
        task: tasks?.find(({id}) => id === itemId),
      };
    }),
    compose(
      withViewerLayout(),
      withFacetCurrentTask(ViewerContext),
      branch(pipe(prop('task'), isNil), renderNothing),
      withProps(({facets}) => ({
        tasks: pipe(
          find(propEq('value', 'eligibility')),
          propOr([], 'items'),
        )(facets),
      })),
      withProps(({tasks}) => ({
        complete: tasks.every(areAllCompleted),
      })),
    ),
  ),
)(Component);
