import {find, last, map, pipe, prop, propEq, propOr, split, pick} from 'ramda';

import {
  FEASIBILITY_REVIEW_FIELDS_FOR_PREVIEW,
  PROJECT_FIELDS_FOR_PREVIEW,
} from './constants';

export const getObjectNames = map(prop('objectName'));

export const getAllPhotos = pipe(
  propOr([], 'tasks'),
  find(propEq('taskType', 'appraisal_property_photos')),
  propOr([], 'appraisalPropertyPhotos'),
);

export const findUrl = (objectName) =>
  pipe(find(propEq('objectName', objectName)), propOr(null, 'url'));

export const mapPropertyPhotos = (propertyPhotos) =>
  propertyPhotos.map((photo) => ({
    ...photo,
    fileName: pipe(propOr('photo.jpg', 'objectName'), split('/'), last)(photo),
  }));

export const getParamsForPreview = ({
  feasibilityReviewId,
  reviewer,
  feasibilityReview,
  project,
  documents,
  propertyPhotos,
  selectedFiles,
  selectedPhotos,
}) => {
  return {
    feasibilityReview: {
      id: feasibilityReviewId,
      reviewerId: reviewer,
      ...pick(FEASIBILITY_REVIEW_FIELDS_FOR_PREVIEW, feasibilityReview),
    },
    project: {
      borrowerId: project?.primaryApplicant?.id,
      ...pick(PROJECT_FIELDS_FOR_PREVIEW, project),
    },
    documents: documents
      .filter(({id}) => selectedFiles.includes(id))
      .map(pick(['id', 'fileName', 'objectName', 'documentType'])),
    propertyPhotos: propertyPhotos
      .filter(({id}) => selectedPhotos.includes(id))
      .map(pick(['id', 'homeownerDescription', 'objectName'])),
  };
};
