import {pathOr, propOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useToggledQuery from '../useToggledQuery';
import {GET_LENDERS} from '../../queries';

export default function useLenders({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_LENDERS,
    options: {
      onCompleted,
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch} = response;
  const lenders = propOr([], 'lenders', data);
  const collection = pathOr([], ['lenders', 'collection'], data);
  const metadata = pathOr({}, ['lenders', 'metadata'], data);

  return {fetch, lenders, data, loading, error, collection, metadata};
}
