import React, {Fragment, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {find, includes, pipe, propEq, propOr} from 'ramda';
import {ModalsContext, RejectAppraisalModal} from 'modules/modals';

import {Box} from '@renofi/components-internal';
import {useAcceptReport} from '@renofi/graphql/src/hooks';

import {Approve, Discard} from '../../icons';

const getCanRequestRevision = pipe(
  propOr({}, 'amc'),
  propOr([], 'features'),
  includes('revision_request'),
);

function AppraisalReportDocumentActions({
  appraisalOrders = [],
  appraisalReport,
  onConfirmRevision,
}) {
  const {id: reportId} = appraisalReport;
  const [appraisalOrder, setAppraisalOrder] = useState(null);
  const {acceptReport, loading: isAcceptingReport} = useAcceptReport();

  const isAccepted = Boolean(appraisalOrder?.reportAcceptedAt);
  const hasRevisionRequest = Boolean(appraisalOrder?.correctionRequestedAt);
  const canRequestRevision = getCanRequestRevision(appraisalOrder);

  useEffect(() => {
    setAppraisalOrder(
      find(
        ({appraisalReportDocuments}) =>
          find(propEq('id', reportId), appraisalReportDocuments),
        appraisalOrders,
      ),
    );
  }, []);

  if (!appraisalOrder) {
    return null;
  }

  return (
    <ModalsContext.Consumer>
      {({onClose, setLoading, setModalProps}) => {
        const modalProps = {
          acceptLabel: 'Reject appraisal',
          component: RejectAppraisalModal,
          hasButtons: true,
          onAccept: ({comments}) => {
            setLoading(true);
            onConfirmRevision({
              appraisalOrder,
              canRequestRevision,
              comments,
              onClose,
            });
          },
          onReject: () => onClose(),
          submitDisabled: ({comments, reason}) =>
            !appraisalOrder || !comments || !reason,
        };

        return (
          <Fragment>
            <Box height={24} ml={16}>
              <Discard
                active={hasRevisionRequest}
                disabled={isAcceptingReport}
                loading={isAcceptingReport}
                onClick={(e) => {
                  e.preventDefault();
                  setModalProps(modalProps);
                }}
              />
            </Box>
            <Box height={24} ml={16}>
              <Approve
                active={isAccepted}
                disabled={isAccepted || isAcceptingReport}
                loading={isAcceptingReport}
                onClick={(e) => {
                  e.preventDefault();
                  acceptReport({
                    variables: {appraisalReportDocumentId: appraisalReport.id},
                  });
                }}
              />
            </Box>
          </Fragment>
        );
      }}
    </ModalsContext.Consumer>
  );
}

AppraisalReportDocumentActions.propTypes = {
  appraisalOrders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  appraisalReport: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onConfirmRevision: PropTypes.func.isRequired,
};

export default AppraisalReportDocumentActions;
