import styled from '@emotion/styled';

export const List = styled.ul({
  paddingLeft: 16,
  paddingRight: 16,
});

export const ListItem = styled.li({
  marginBottom: 8,
});
