import React, {Fragment, useContext, useEffect, useMemo} from 'react';

import PropTypes from 'prop-types';
import {find, propEq} from 'ramda';
import {useViewerNavigation} from 'modules/viewer-v2';
import {ItemTabsBar} from 'modules/viewer-v2/components/Layout';

import queryString from '@renofi/utilities/src/queryString';

import {
  COBORROWER_TASK_TYPES,
  APPROVABLE_TASK_TYPES,
  EMPLOYMENT_HISTORY_TASK_TYPE,
  PERSONAL_INFO_TASK_TYPE,
  REAL_ESTATE_TASK_TYPE,
} from '../../../constants';
import ViewerV2Context from '../../../context';
import {ApprovableTaskActions} from '../../Layout';
import {
  getItemKeyByTask,
  getTabsFromEmployment,
  getTabsFromRealEstate,
  getTabsFromTaskDocuments,
} from '../utils';

import TabContent from './TabContent';
import BorrowersTabs from './BorrowersTabs';
import EmploymentTabs from './EmploymentTabs';
import PropertyTabs from './PropertyTabs';
import {TASK_TITLE_MAPPER} from './constants';

const TaskItemsBar = ({task}) => {
  const {getItemIdByDirection} = useContext(ViewerV2Context);
  const {history, params, search} = useViewerNavigation();
  const {itemId} = params;
  const hasCoborrowerTabs = COBORROWER_TASK_TYPES.includes(task?.taskType);
  const isEmploymentTask = task?.taskType === EMPLOYMENT_HISTORY_TASK_TYPE;
  const isPersonalInfoTask = task?.taskType === PERSONAL_INFO_TASK_TYPE;
  const isRealEstateTask = task?.taskType === REAL_ESTATE_TASK_TYPE;
  const currentValue = search?.documentId || search?.employmentId || itemId;

  const itemKey = useMemo(() => getItemKeyByTask(task), [task?.taskType]);

  const tabs = useMemo(() => {
    const {canHaveDocument, taskType, title} = task || {};
    const label = TASK_TITLE_MAPPER[taskType] || title;

    switch (true) {
      case APPROVABLE_TASK_TYPES.includes(taskType):
        return [
          {
            label: (
              <TabContent
                extra={<ApprovableTaskActions task={task} />}
                title={label}
              />
            ),
            value: task?.id,
          },
        ];
      case isRealEstateTask:
        return getTabsFromRealEstate({search, task});
      case isEmploymentTask:
        return getTabsFromEmployment({search, task});
      case canHaveDocument:
        return getTabsFromTaskDocuments({search, task});
      default:
        return [
          {
            label,
            value: task?.id,
          },
        ];
    }
  }, [search, task]);

  useEffect(() => {
    const initialTabId = getItemIdByDirection(tabs, 'value');

    // If no specific itemId found in query-string, goto the first tab.
    if (!search?.[itemKey] && !!initialTabId) {
      goToNewTab(initialTabId, history.replace);
    }
  }, [itemKey, tabs]);

  const goToNewTab = (tabId, routerAction = history.push) => {
    const {canHaveDocument, documents = []} = task || {};
    let doc, to;

    switch (true) {
      case isRealEstateTask:
        doc = find(propEq('id', tabId), documents);
        to = queryString.stringify({
          ...search,
          [itemKey]: tabId,
        });
        return routerAction(`?${to}`);
      case isEmploymentTask:
        to = queryString.stringify({
          employmentId: search?.employmentId,
          documentId: tabId,
        });
        return routerAction(`?${to}`);
      case isPersonalInfoTask:
        to = queryString.stringify({
          borrowerRole: search?.borrowerRole || 'borrower',
        });
        return routerAction(`?${to}`);
      case canHaveDocument:
        doc = find(propEq('id', tabId), documents);
        to = queryString.stringify({
          ...search,
          borrowerRole: doc?.borrowerRole || 'borrower',
          [itemKey]: tabId,
        });
        return routerAction(`?${to}`);
      default:
        return null;
    }
  };

  return (
    <Fragment>
      {hasCoborrowerTabs && <BorrowersTabs task={task} />}
      {isEmploymentTask && <EmploymentTabs />}
      {isRealEstateTask && <PropertyTabs task={task} />}
      <ItemTabsBar
        itemKey={itemKey}
        onChange={goToNewTab}
        tabs={tabs}
        value={currentValue}
      />
    </Fragment>
  );
};

TaskItemsBar.propTypes = {
  task: PropTypes.shape({
    canHaveDocument: PropTypes.bool,
    facet: PropTypes.string,
    id: PropTypes.string.isRequired,
    taskType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
};

export default TaskItemsBar;
