import React, {useCallback, useState} from 'react';

import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {isEmpty} from 'ramda';
import {useGetAppraisalOrder} from 'lib/appraisal';

import {Box, Button, Flex, Modal, Tooltip} from '@renofi/components-internal';
import {Provider as ThemeProvider} from '@renofi/theme/src';

import ValidationTooltipContent from '../ValidationTooltipContent';

import AppraisalDetails from './components/AppraisalDetails';
import AppraisalPreview from './components/AppraisalPreview';

function OrderAppraisalModal({onClose, previousAppraisalOrderId}) {
  const {projectId} = useParams();
  const [preview, setPreview] = useState(false);

  const {
    amcCode,
    appraisalProduct,
    documents,
    orderParams,
    isFinalInspection,
    isLoading,
    onCancel,
    onSubmit,
    onToggleDocument,
    onUpdate,
    selected,
    setAmcCode,
    setAppraisalProduct,
    invalidFields,
  } = useGetAppraisalOrder({
    onClose,
    previousAppraisalOrderId,
  });

  const isSubmitDisabled = !isEmpty(invalidFields);

  const onClickSubmit = useCallback(() => {
    if (!preview) {
      return setPreview(true);
    }

    onSubmit();
  }, [preview]);

  const onCloseModal = () => {
    setPreview(false);
    onCancel();
    onClose();
  };

  return (
    <ThemeProvider theme="light">
      <Modal
        lock
        fixed
        show
        width={770}
        maxHeight="90vh"
        footer={
          <Flex justifyContent="flex-end" alignItems="center">
            <Box mr="4px">
              <Button variant="secondary" onClick={onCloseModal}>
                Cancel
              </Button>
            </Box>
            <Box ml="4px">
              <Tooltip
                disable={!isSubmitDisabled}
                place="top"
                content={
                  <ValidationTooltipContent invalidFields={invalidFields} />
                }>
                <div>
                  <Button
                    loading={isLoading}
                    disabled={isSubmitDisabled}
                    onClick={onClickSubmit}>
                    {preview ? 'Order' : 'Next'}
                  </Button>
                </div>
              </Tooltip>
            </Box>
          </Flex>
        }
        header="Order Appraisal"
        onClose={onCloseModal}>
        {!preview && (
          <AppraisalDetails
            {...orderParams}
            amcCode={amcCode}
            formType={appraisalProduct?.renofiFormType}
            appraisalProduct={appraisalProduct}
            documents={documents}
            disabled={isSubmitDisabled}
            isFinalInspection={isFinalInspection}
            isLoading={isLoading}
            selected={selected}
            onToggleDocument={onToggleDocument}
            onUpdateOrder={onUpdate}
            onUpdateAmcCode={setAmcCode}
            onUpdateAppraisalProduct={setAppraisalProduct}
          />
        )}
        {preview && (
          <AppraisalPreview
            amcCode={amcCode}
            appraisalProduct={appraisalProduct}
            selected={selected}
            appraisalOrder={orderParams}
            projectId={projectId}
          />
        )}
      </Modal>
    </ThemeProvider>
  );
}

OrderAppraisalModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  previousAppraisalOrderId: PropTypes.string,
};

export default OrderAppraisalModal;
