import React from 'react';

import {head} from 'ramda';
import AppraisalWidget from 'modules/viewer-v2/components/Appraisal';

import {Loader} from '@renofi/components-internal';
import {useAppraisalOrders} from '@renofi/graphql';

import {useTaskByCurrentItemId, useViewerNavigation} from '../../hooks';
import {getMostRecentReport} from '../../utils';
import AppraisalStatusBar from '../AppraisalStatusBar';
import {ItemView, DataTypeViewport} from '../Layout';
import PdfDocumentView from '../PdfDocumentView';

import ItemsBar from './components/ItemsBar';

const AppraisalReportType = () => {
  const {params} = useViewerNavigation();
  const {projectId} = params;
  const {loading: isLoadingProject, project} = useTaskByCurrentItemId();
  const {appraisalOrders, loading: isLoadingOrders} = useAppraisalOrders({
    variables: {projectId},
  });

  const loading = isLoadingProject || isLoadingOrders;
  const currentAppraisalOrder = head(appraisalOrders ?? []);
  const appraisalReport = getMostRecentReport(
    currentAppraisalOrder?.appraisalReportDocuments,
  );

  if (!project) {
    return null;
  } else if (loading) {
    return <Loader />;
  }

  return (
    <DataTypeViewport
      sidebar={<AppraisalWidget projectId={projectId} />}
      statusBar={<AppraisalStatusBar projectId={projectId} />}>
      <ItemsBar projectId={projectId} />
      <ItemView>
        <PdfDocumentView doc={appraisalReport} />
      </ItemView>
    </DataTypeViewport>
  );
};

export default AppraisalReportType;
