/* eslint-disable max-lines */
import React, {useEffect, useMemo} from 'react';

import PropTypes from 'prop-types';
import {find, fromPairs, map, pipe, prop, propEq, propOr} from 'ramda';
import {useParams} from 'react-router-dom';

import {
  useAppraisalManagementCompanies,
  useEnums,
  usePreviewAppraisalPackage,
  useSignedUrls,
} from '@renofi/graphql/src/hooks';
import {
  Box,
  Card,
  Checkbox,
  EmailField,
  Flex,
  Link,
  PhoneField,
  SelectField,
  Switch,
  Table,
  Text,
  TextField,
} from '@renofi/components-internal';
import {External, File} from '@renofi/icons';
import {actionRegular, basic55} from '@renofi/theme';
import {openTab} from '@renofi/utilities/src/window';

import PropertyDetails from './components/PropertyDetails';

const LAZY = {lazy: true};

const findUrl = (objectName) =>
  pipe(find(propEq('objectName', objectName)), propOr(null, 'url'));

const AppraisalDetails = ({
  disabled,
  amcCode,
  appraisalProduct,
  documents,
  formType,
  isFinalInspection,
  isLoading,
  selected,
  contactFirstName,
  contactLastName,
  contactEmail,
  contactHomePhone,
  contactWorkPhone,
  priorityOrder,
  propertyDetails,
  onToggleDocument,
  onUpdateOrder,
  onUpdateAmcCode,
  onUpdateAppraisalProduct,
}) => {
  const changeHandler = (field) => (value) => onUpdateOrder(field, value);

  const {projectId} = useParams();
  const {enums} = useEnums();
  const {loading: isPreviewing, fetch: previewAppraisalPackage} =
    usePreviewAppraisalPackage(LAZY);
  const {appraisalManagementCompanies} = useAppraisalManagementCompanies();
  const company = find(propEq('code', amcCode), appraisalManagementCompanies);
  const enabledProducts = propOr([], 'enabledAppraisalProducts', company);

  const {fetch: getSignedUrls, ...query} = useSignedUrls({lazy: true});
  const signedUrls = propOr([], 'signedUrls', query);

  const documentTypes = useMemo(() => {
    return pipe(
      propOr([], 'documentTypes'),
      map(({label, value}) => [value, label]),
      fromPairs,
    )(enums);
  }, [enums?.documentTypes?.length]);

  const appraisalTypeOptions = useMemo(() => {
    return enabledProducts.map(({productName, renofiFormType}) => ({
      disabled: false,
      label: productName,
      value: renofiFormType,
    }));
  }, [enabledProducts.length]);

  useEffect(() => {
    if (documents?.length) {
      getSignedUrls({
        variables: {objectNames: map(prop('objectName'))(documents)},
      });
    }
  }, [documents]);

  const onPropertyDetailsChange = (propertyDetails) => {
    changeHandler('propertyDetails')(propertyDetails);
  };

  const onChangeProduct = (code) => {
    onUpdateAppraisalProduct(
      find(propEq('renofiFormType', code), enabledProducts),
    );
  };

  const onPreviewPackage = async () => {
    const appraisalProductId = appraisalProduct?.id;
    const rsp = await previewAppraisalPackage({
      variables: {
        appraisalOrder: {
          appraisalProductId,
          contactEmail,
          contactFirstName,
          contactHomePhone,
          contactLastName,
          contactWorkPhone,
          homeownerName: '',
        },
        projectId,
        documentIds: selected,
      },
    });
    const {url} = rsp?.data?.previewAppraisalPackage || {};
    openTab(url);
  };

  return (
    <Box>
      <Text fontSize={16} lineHeight="24px" mb={24}>
        Appraisal details
      </Text>
      <Flex width={1} flexWrap="wrap">
        <Box width={1 / 2} pr="8px">
          <SelectField
            disabled={isLoading}
            value={amcCode}
            label="Order from"
            placeholder="Select"
            options={appraisalManagementCompanies.map(({code, name}) => ({
              label: name,
              value: code,
            }))}
            onChange={onUpdateAmcCode}
          />
        </Box>
        <Box width={1}>
          <SelectField
            disabled={!company || isLoading}
            value={formType}
            label="Form"
            placeholder="Select"
            options={appraisalTypeOptions}
            onChange={onChangeProduct}
          />
        </Box>
        <Box width={1}>
          <Checkbox
            disabled={isFinalInspection || isLoading}
            checked={priorityOrder}
            label="This is a rush appraisal (additional fee of approximately $150)"
            onChange={changeHandler('priorityOrder')}
          />
        </Box>
      </Flex>
      <Text fontSize={16} lineHeight="24px" mb={24} mt={32}>
        Property Contact Details
      </Text>

      <Flex width={1}>
        <Box width={1 / 2} mr="8px">
          <TextField
            active
            disabled={isLoading}
            label="Contact First Name"
            value={contactFirstName}
            onChange={changeHandler('contactFirstName')}
          />
        </Box>
        <Box width={1 / 2} ml="8px">
          <TextField
            active
            disabled={isLoading}
            label="Contact Last Name"
            value={contactLastName}
            onChange={changeHandler('contactLastName')}
          />
        </Box>
      </Flex>
      <Flex width={1}>
        <Box width={1 / 2} mr="8px">
          <EmailField
            active
            disabled={isLoading}
            label="Contact Email"
            value={contactEmail}
            onChange={changeHandler('contactEmail')}
          />
        </Box>
        <Box width={1 / 2} ml="8px">
          <PhoneField
            active
            disabled={isLoading}
            label="Contact Home Phone"
            value={contactHomePhone}
            onChange={changeHandler('contactHomePhone')}
          />
        </Box>
      </Flex>
      <Flex width={1}>
        <Box width={1 / 2} mr="8px">
          <PhoneField
            active
            disabled={isLoading}
            label="Contact Work Phone"
            value={contactWorkPhone}
            onChange={changeHandler('contactWorkPhone')}
          />
        </Box>
        <Box width={1 / 2} ml="8px"></Box>
      </Flex>

      {isFinalInspection && (
        <PropertyDetails
          onChange={onPropertyDetailsChange}
          propertyDetails={propertyDetails}
        />
      )}

      <Flex alignItems="baseline">
        <Text fontSize={16} lineHeight="24px" mb={24}>
          Package
        </Text>
        <Flex alignItems="baseline" ml={12}>
          <Link
            disabled={disabled || isPreviewing}
            css={{
              fontSize: 14,
              lineHeight: '22px',
            }}
            onClick={onPreviewPackage}>
            Preview generated package PDF{' '}
            <External
              width={12}
              height={12}
              color={disabled ? basic55 : actionRegular}
            />
          </Link>
        </Flex>
      </Flex>

      {Boolean(documents?.length) && (
        <Card p={0}>
          <Table condensed>
            <tbody>
              <tr>
                <td colSpan={2}>Documents</td>
                <td css={{textAlign: 'right !important'}}>Send</td>
              </tr>
              {documents.map(({id, documentType, fileName, objectName}) => {
                const href = findUrl(objectName)(signedUrls);
                return (
                  <tr key={id}>
                    <td>
                      <Flex alignItems="center">
                        <Box mr={20}>
                          <File height={16} />
                        </Box>
                        <Box>{documentTypes[documentType]}</Box>
                      </Flex>
                    </td>
                    <td>
                      <Link blank disabled={!href} href={href}>
                        {fileName}
                      </Link>
                    </td>
                    <td>
                      <Flex
                        width={1}
                        justifyContent="flex-end"
                        alignItems="center">
                        <Switch
                          disabled={isLoading || !formType || !amcCode}
                          checked={selected.includes(id)}
                          onClick={() => onToggleDocument(id)}
                        />
                        <Text ml={16}>
                          {selected.includes(id) ? 'Yes' : 'No'}
                        </Text>
                      </Flex>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card>
      )}
    </Box>
  );
};

AppraisalDetails.propTypes = {
  appraisalProduct: PropTypes.shape({
    id: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  amcCode: PropTypes.string,
  companies: PropTypes.arrayOf(PropTypes.object),
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      objectName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  contactFirstName: PropTypes.string,
  contactLastName: PropTypes.string,
  contactEmail: PropTypes.string,
  contactHomePhone: PropTypes.string,
  contactWorkPhone: PropTypes.string,
  formType: PropTypes.string,
  isFinalInspection: PropTypes.bool,
  isLoading: PropTypes.bool,
  priorityOrder: PropTypes.bool,
  propertyDetails: PropTypes.shape({
    city: PropTypes.string,
    county: PropTypes.string,
    propertyType: PropTypes.string,
    streetAddressOne: PropTypes.string,
    streetAddressTwo: PropTypes.string,
    zipCode: PropTypes.string,
  }),
  onToggleDocument: PropTypes.func.isRequired,
  onUpdateOrder: PropTypes.func.isRequired,
  onUpdateAmcCode: PropTypes.func.isRequired,
  onUpdateAppraisalProduct: PropTypes.func.isRequired,
};

export default AppraisalDetails;
