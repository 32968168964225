import {
  find,
  flatten,
  pick,
  pipe,
  prop,
  propEq,
  propOr,
  sort,
  values,
  reject,
  isNil,
} from 'ramda';

import {taskTypes} from '@renofi/utilities/src/enums';

const ORDERING = ['SubjectProperty', 'AdditionalProperty', 'SoldProperty'];

export default (project = {}) => {
  if (!project?.tasks?.length) {
    return [];
  }

  return pipe(
    propOr([], 'tasks'),
    find(propEq('taskType', taskTypes.real_estate_owned)),
    propOr({}, 'realEstateOwned'),
    pick(['additionalProperties', 'soldProperties', 'subjectProperty']),
    values,
    flatten,
    reject(isNil),
    sort((left, right) => {
      return (
        ORDERING.indexOf(prop('__typename', left)) -
        ORDERING.indexOf(prop('__typename', right))
      );
    }),
  )(project);
};
