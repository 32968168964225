import React, {useContext, useEffect} from 'react';

import PropTypes from 'prop-types';
import {map, prop} from 'ramda';
import {useViewerNavigation} from 'modules/viewer-v2/hooks';

import {Tabs} from '@renofi/components-internal/src/next';
import {Box, Flex} from '@renofi/components-internal';
import {basic80, basic88} from '@renofi/theme';
import queryString from '@renofi/utilities/src/queryString';

import ViewerV2Context from '../../../../context';

import NextPrevButton from './components/NextPrevButton';
import Tab from './components/Tab';

const ItemTabsBar = ({itemKey = 'documentId', onChange, tabs = [], value}) => {
  const {setIsLeftToRight, setItemsBarCache} = useContext(ViewerV2Context);
  const {history, search} = useViewerNavigation();
  const currentIndex = tabs.findIndex((t) => t.value === value);

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementById(value);
      if (el) {
        el.scrollIntoView({block: 'center', behavior: 'auto'});
      }
    }, 500);
  }, []);

  useEffect(() => setItemsBarCache(map(prop('value'), tabs)), [tabs]);

  const onClickArrow = (forward) => {
    setIsLeftToRight(forward);
    const newIndex = forward
      ? Math.max(currentIndex + 1, 0)
      : Math.max(currentIndex - 1, 0);
    const {value} = tabs[newIndex] || {};

    if (!value) {
      return;
    }

    const to = queryString.stringify({
      ...search,
      [itemKey]: value,
    });
    history.push(`?${to}`);
  };

  const onTabChange = (...args) => {
    setIsLeftToRight(true);
    onChange(...args);
  };

  return (
    <Flex
      pl={2}
      pr={2}
      pt={2}
      minHeight={60}
      width={1}
      backgroundColor={basic80}>
      <Box
        ml={2}
        flex="inherit"
        css={{
          overflow: 'auto',
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        width={1}>
        <Tabs
          activeColor={basic88}
          tabComponent={Tab}
          tabs={tabs}
          value={value}
          onChange={onTabChange}
        />
      </Box>
      <Flex mr={0} flexDirection="column">
        <NextPrevButton
          back
          disabled={currentIndex === 0}
          onClick={() => onClickArrow(false)}
        />
        <NextPrevButton
          back={false}
          disabled={currentIndex === tabs.length - 1}
          onClick={() => onClickArrow(true)}
        />
      </Flex>
    </Flex>
  );
};

ItemTabsBar.propTypes = {
  itemKey: PropTypes.string,
  onChange: PropTypes.func,
  tabs: Tabs.propTypes.tabs,
  value: Tabs.propTypes.value,
};

export default ItemTabsBar;
