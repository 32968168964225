import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import useToggledQuery from '../useToggledQuery';
import {GET_PROJECT_NOTES} from '../../queries';

export default function useProjectNotes({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_PROJECT_NOTES,
    options: {
      fetchPolicy,
      onCompleted,
      ...options,
    },
  });
  const {data, error, loading, fetch, refetching} = response;
  const notes = pathOr([], ['projectById', 'notes'], data);

  return {fetch, notes, data, loading, error, refetching};
}
