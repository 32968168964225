import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {head} from 'ramda';
import {useViewerNavigation} from 'modules/viewer-v2/hooks';
import {ItemTabsBar} from 'modules/viewer-v2/components/Layout';

import {useAppraisalOrders} from '@renofi/graphql';
import noop from '@renofi/utilities/src/noop';

import {getMostRecentReport} from '../../../utils';

import TabContent from './TabContent';

const AppraisalReportItemsBar = ({projectId}) => {
  const {search} = useViewerNavigation();
  const currentValue = search?.documentId;

  const {appraisalOrders} = useAppraisalOrders({variables: {projectId}});

  const currentAppraisalOrder = head(appraisalOrders ?? []);
  const appraisalReport = getMostRecentReport(
    currentAppraisalOrder?.appraisalReportDocuments,
  );

  const tabs = useMemo(
    () =>
      appraisalReport
        ? [
            {
              label: (
                <TabContent
                  appraisalOrders={appraisalOrders}
                  appraisalReport={appraisalReport}
                />
              ),
              value: appraisalReport.id,
            },
          ]
        : [],
    [appraisalReport?.id],
  );

  return <ItemTabsBar onChange={noop} tabs={tabs} value={currentValue} />;
};

AppraisalReportItemsBar.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default AppraisalReportItemsBar;
