import {propOr} from 'ramda';

import useToggledQuery from '../useToggledQuery';
import {GET_PRODUCT_DETAILS_CONFIRMATION_SUMMARY} from '../../queries';

export default function useProductDetailsConfirmationSummary({
  fetchPolicy = 'cache-first',
  lazy = false,
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_PRODUCT_DETAILS_CONFIRMATION_SUMMARY,
    options: {
      fetchPolicy,
    },
  });

  const {data, error, loading, fetch} = response;
  const productDetailsConfirmationSummary = propOr(
    null,
    'productDetailsConfirmationSummary',
    data,
  );

  return {error, fetch, productDetailsConfirmationSummary, loading};
}
