import React, {Fragment} from 'react';

import PropTypes from 'prop-types';
import {lensProp, set} from 'ramda';

import {
  Box,
  Flex,
  SelectField,
  StatesField,
  Text,
  TextField,
} from '@renofi/components-internal';
import {getStateValueByName} from '@renofi/utilities/src/states';
import {propertyTypes} from '@renofi/utilities/src/enumTypes';

function PropertyDetails({onChange, propertyDetails}) {
  const state = getStateValueByName(propertyDetails.state);

  const onInputChange = (key, value) => {
    onChange(set(lensProp(key), value, propertyDetails));
  };

  return (
    <Fragment>
      <Text fontSize={16} lineHeight="24px" mb={24}>
        Property Details
      </Text>
      <Flex width={1}>
        <Box flex={0.5} mr={3}>
          <SelectField
            label="Property type"
            options={propertyTypes}
            onChange={(v) => onInputChange('propertyType', v)}
            placeholder="Select property type"
            value={propertyDetails.propertyType}
          />
        </Box>

        <Box flex={0.5}>
          <TextField
            active
            label="Street address"
            onChange={(v) => onInputChange('streetAddressOne', v)}
            placeholder="Property, street address"
            value={propertyDetails.streetAddressOne}
          />
        </Box>
      </Flex>

      <Flex width={1}>
        <Box flex={0.5} mr={3}>
          <TextField
            active
            label="City"
            onChange={(v) => onInputChange('city', v)}
            placeholder="Property, city location"
            value={propertyDetails.city}
          />
        </Box>

        <Box flex={0.5}>
          <StatesField
            label="State"
            onChange={(v) => onInputChange('state', v)}
            value={state}
          />
        </Box>
      </Flex>

      <Flex width={1}>
        <Box flex={0.5} mr={3}>
          <TextField
            active
            label="Zip"
            onChange={(v) => onInputChange('zipCode', v)}
            placeholder="Property, zip code"
            value={propertyDetails.zipCode}
          />
        </Box>

        <Box flex={0.5}>
          <TextField
            active
            label="County"
            onChange={(v) => onInputChange('county', v)}
            placeholder="Property, county"
            value={propertyDetails.county}
          />
        </Box>
      </Flex>
    </Fragment>
  );
}

PropertyDetails.propTypes = {
  onChange: PropTypes.func.isRequired,
  propertyDetails: PropTypes.shape({
    city: PropTypes.string,
    county: PropTypes.string,
    propertyType: PropTypes.string,
    state: PropTypes.string,
    streetAddressOne: PropTypes.string,
    streetAddressTwo: PropTypes.string,
    zipCode: PropTypes.string,
  }),
};

export default PropertyDetails;
