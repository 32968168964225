import {propOr} from 'ramda';

import {TaskItemActions} from '../../Layout';
import TabContent from '../components/TabContent';

export default ({search, task}) => {
  const employmentHistories = propOr([], 'employmentHistories', task);
  const {employmentId} = search;
  const history = employmentId
    ? employmentHistories.find(({id}) => id === employmentId)
    : employmentHistories[0];
  const documents = history?.documents || [];

  return documents.map((doc) => {
    return {
      label: (
        <TabContent
          extra={
            <TaskItemActions
              {...doc}
              taskId={task?.id}
              taskType={task?.taskType}
            />
          }
          title={doc.fileName}
        />
      ),
      value: doc.id,
    };
  });
};
