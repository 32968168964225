import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {v4 as uuid} from 'uuid';
import {find, omit, pick, propEq} from 'ramda';

import {usePreview} from '@renofi/utilities/src/hooks';
import {
  useAppraisalManagementCompanies,
  useGetUser,
} from '@renofi/graphql/src/hooks';

const AppraisalPreview = ({
  amcCode,
  appraisalProduct,
  appraisalOrder,
  projectId,
  selected: documentIds,
  getAppraisalOrderPreview,
}) => {
  const {appraisalManagementCompanies} = useAppraisalManagementCompanies();
  const {user} = useGetUser();
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    (async () => {
      const company = find(
        propEq('code', amcCode),
        appraisalManagementCompanies,
      );
      const formType = appraisalProduct?.renofiFormType;

      const response = await getAppraisalOrderPreview({
        ...omit(['priorityOrder', 'propertyDetails'], appraisalOrder),
        amc: pick(['code', 'name', 'hasApi'], company),
        id: uuid(),
        createdById: user.id,
        documentIds,
        projectId,
        formType,
      });

      setPreview(response);
    })();
  }, []);

  usePreview(preview, 'appraisalOrder');
  return <div id="appraisalOrder" />;
};

AppraisalPreview.propTypes = {
  amcCode: PropTypes.string.isRequired,
  appraisalOrder: PropTypes.shape({
    priorityOrder: PropTypes.bool,
  }),
  appraisalProduct: PropTypes.shape({
    renofiFormType: PropTypes.string.isRequired,
  }).isRequired,
  getAppraisalOrderPreview: PropTypes.func.isRequired,
  preview: PropTypes.object,
  projectId: PropTypes.string.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string),
};

export default AppraisalPreview;
