import {propOr} from 'ramda';

import {
  APPRAISAL_RECENT_TRANSACTIONS,
  APPRAISAL_REPORT_DOCUMENT_TYPE,
  DOCUMENT_TASK_TYPES,
  NON_DOCUMENT_TASK_TYPES,
  TAB_TYPES,
} from '../constants';

const TASK_TYPES_MAP = {
  // key: [title, details]
  [DOCUMENT_TASK_TYPES.government_issued_id]: ['ID', 'Government ID'],
  [DOCUMENT_TASK_TYPES.mortgage_statement]: [
    'Mortgage',
    'Most recent statement',
  ],
  [DOCUMENT_TASK_TYPES.renovation_contract]: ['Contract', 'Reno contract'],
  [DOCUMENT_TASK_TYPES.renovation_plan]: ['Plans', 'Reno plans'],
  [DOCUMENT_TASK_TYPES.contractor_info]: ['Contractor', 'Details'],
  [DOCUMENT_TASK_TYPES.home_insurance]: ['Insurance', 'Owner’s insurance'],
  [DOCUMENT_TASK_TYPES.insurance_coverage_proof]: [
    'Coverage proof',
    'Requested proof of coverage',
  ],
  [NON_DOCUMENT_TASK_TYPES.appraisal_property_details]: [
    'Current home',
    'Characteristics',
  ],
  [NON_DOCUMENT_TASK_TYPES.appraisal_renovation_details]: [
    'Improvements',
    'Proposed improvements',
  ],
  [NON_DOCUMENT_TASK_TYPES.appraisal_property_photos]: [
    'Photos',
    'Recent photos',
  ],
  [NON_DOCUMENT_TASK_TYPES.appraisal_comparable_sales]: [
    'Comps',
    'Recent sales',
  ],
  [APPRAISAL_RECENT_TRANSACTIONS]: ['Purchase/Refi', 'Within last 12 months'], // used via right panel
  [NON_DOCUMENT_TASK_TYPES.sms_consent_renovation_progress]: [], // not used
};

const DOCUMENT_TYPES_MAP = {
  [APPRAISAL_REPORT_DOCUMENT_TYPE]: ['Appraisal', 'Received'],
};

const defaultTitleDetails = ['', ''];

export default function getTabTitleDetails(item) {
  const title = propOr(null, 'title', item);
  const details = propOr(null, 'details', item);
  if (title && details) {
    return [title, details];
  }

  const isTask = item.type === TAB_TYPES.task;
  const taskType = propOr(null, 'taskType', item);
  const documentType = propOr(null, 'documentType', item);

  return isTask
    ? propOr(defaultTitleDetails, taskType, TASK_TYPES_MAP)
    : propOr(defaultTitleDetails, documentType, DOCUMENT_TYPES_MAP);
}
