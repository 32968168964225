import React, {Fragment, useCallback} from 'react';

import PropTypes from 'prop-types';
import TASK_STATUSES from 'lib/task/taskStatuses';

import {Box} from '@renofi/components-internal';
import {useChangeTaskStatus} from '@renofi/graphql/src/hooks';

import {Approve, Discard} from '../../../../icons';

function ApprovableTaskActions({task}) {
  const {changeTaskStatus, loading} = useChangeTaskStatus();
  const {id, status: taskStatus} = task;
  const isCompleted = taskStatus === TASK_STATUSES.COMPLETED;
  const isOutstanding = taskStatus === TASK_STATUSES.OUTSTANDING;

  const onClickIcon = useCallback((status) => {
    changeTaskStatus({
      variables: {
        id,
        status,
      },
    });
  });

  return (
    <Fragment>
      <Box height={24} ml={16}>
        <Discard
          active={isOutstanding}
          loading={loading}
          onClick={(e) => {
            e.preventDefault();
            onClickIcon(TASK_STATUSES.OUTSTANDING);
          }}
        />
      </Box>
      <Box height={24} ml={16}>
        <Approve
          active={isCompleted}
          loading={loading}
          onClick={(e) => {
            e.preventDefault();
            onClickIcon(TASK_STATUSES.COMPLETED);
          }}
        />
      </Box>
    </Fragment>
  );
}

ApprovableTaskActions.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
};

export default ApprovableTaskActions;
