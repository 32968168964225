import React, {Fragment} from 'react';

import PropTypes from 'prop-types';
import {Col, RequiredText, Row, getPercentageChange} from 'modules/viewer-v2';

import {TextField} from '@renofi/components-internal';

import {NO_VALUE} from '../../constants';

import {InfoText, LabelText} from './styled';

function BorrowerAppraisalValues({
  appraisalValue = NO_VALUE,
  borrowerValue,
  disabled = false,
  esimated,
  isAppraisalCompleted,
  onChange,
  roi = NO_VALUE,
  title,
}) {
  const roiPrefix = esimated ? 'Estimated' : 'Actual';
  let percentage = NO_VALUE;

  if (appraisalValue !== NO_VALUE) {
    const difference = appraisalValue - borrowerValue;
    percentage = getPercentageChange(difference, borrowerValue);
  }

  return (
    <Fragment>
      <Row title={title}>
        <Col>
          <TextField
            value={borrowerValue}
            leftIcon="dollar"
            money
            label="Homeowner"
          />
        </Col>
        <Col>
          <TextField
            disabled={disabled}
            help={
              isAppraisalCompleted ? (
                <RequiredText show={!appraisalValue}>required</RequiredText>
              ) : null
            }
            value={appraisalValue}
            leftIcon="dollar"
            onChange={(v) => onChange(Number(v))}
            money
            label="Appraiser"
          />
        </Col>
      </Row>
      {isAppraisalCompleted && (
        <Row>
          <Col>
            <LabelText>Difference</LabelText>
            <InfoText>{percentage}</InfoText>
          </Col>
          <Col>
            <LabelText>{roiPrefix} ROI</LabelText>
            <InfoText>{roi}</InfoText>
          </Col>
        </Row>
      )}
    </Fragment>
  );
}

BorrowerAppraisalValues.propTypes = {
  appraisalValue: PropTypes.number,
  borrowerValue: PropTypes.number,
  disabled: PropTypes.bool,
  esimated: PropTypes.bool,
  isAppraisalCompleted: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  roi: PropTypes.string,
  title: PropTypes.string,
};

export default BorrowerAppraisalValues;
