import React from 'react';

import PropTypes from 'prop-types';

import {Flex} from '@renofi/components-internal';

function DeleteTask({task}) {
  return <Flex>Are you sure you want to delete the "{task?.title}" task?</Flex>;
}

DeleteTask.propTypes = {
  task: PropTypes.shape({
    title: PropTypes.string,
  }),
};

export default DeleteTask;
