import React from 'react';

import PropTypes from 'prop-types';
import asyncComponent from 'lib/asyncComponent';
import {isSplitWideLayout} from 'lib/layoutHelper';

import {Box} from '@renofi/components-internal';

import Complete from './components/Complete';

const BorrowerIncomeSources = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "viewer-eligibility-income-sources" */ './components/BorrowerIncomeSources'
    ),
);

const AssetsStatement = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "viewer-eligibility-assets" */ './components/AssetsStatement'
    ),
);

const EmploymentDetails = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "viewer-eligibility-assets" */ './components/EmploymentDetails'
    ),
);

const Mortgage = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "viewer-eligibility-mortgage" */ './components/Mortgage'
    ),
);

const GovernmentIdStatement = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "viewer-eligibility-tax" */ './components/GovernmentIdStatement/Component'
    ),
);
const RealEstateOwnedStatement = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "viewer-eligibility-tax" */ './components/RealEstateOwnedStatement/Component'
    ),
);

const Eligibility = ({complete, layout, task, project}) => {
  const wide = isSplitWideLayout(layout);
  const padding = complete ? 0 : wide ? 72 : 116;
  if (!task) {
    return null;
  }

  return (
    <Box pb={padding}>
      {task.taskType === 'mortgage_statement' && <Mortgage />}
      {task.taskType === 'government_issued_id' && (
        <GovernmentIdStatement taskDetails={task} />
      )}
      {task.taskType === 'real_estate_owned' && (
        <RealEstateOwnedStatement taskDetails={task} project={project} />
      )}
      {task.taskType === 'employment_history' && (
        <EmploymentDetails task={task} />
      )}
      {task.taskType === 'income' && (
        <BorrowerIncomeSources task={task} project={project} />
      )}
      {task.taskType === 'assets' && (
        <AssetsStatement task={task} project={project} />
      )}
      {!complete && <Complete facet="eligibility" />}
    </Box>
  );
};

Eligibility.propTypes = {
  complete: PropTypes.bool,
  layout: PropTypes.string,
  project: PropTypes.object,
  task: PropTypes.shape({
    taskType: PropTypes.string.isRequired,
  }),
};

export default Eligibility;
