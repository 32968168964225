import React, {useContext, useMemo} from 'react';

import {filter, propEq} from 'ramda';
import TASK_STATUSES from 'lib/task/taskStatuses';
import {useViewerNavigation} from 'modules/viewer-v2/hooks';

import {useInsuranceReview, useProject} from '@renofi/graphql';

import ViewerV2Context from '../../../../context';
import {getTabTitleDetails, isTaskReviewed} from '../../../../utils';
import {getQueryStringByTaskType} from '../TasksFacet/utils';
import Group from '../Group';
import Tab from '../Tab';
import {getInsuranceReviewStatus} from '../../utils';

const InsuranceGroup = () => {
  const {onGroupPathsUpdate} = useContext(ViewerV2Context);
  const {params} = useViewerNavigation();
  const {projectId, itemId} = params;

  const {project} = useProject({variables: {id: projectId}});
  const {insuranceReview, loading} = useInsuranceReview({projectId});

  const {facetStatus, facetVariant, tasks} = useMemo(() => {
    const tasks = filter(propEq('facet', 'insurance'), project?.tasks || []);
    const [facetVariant, facetStatus] = getInsuranceReviewStatus({
      items: tasks.map((task) => ({...task, type: 'task'})),
      insuranceReview,
    });

    return {facetStatus, facetVariant, tasks};
  }, [insuranceReview, project?.tasks?.length]);

  if (loading || !tasks.length) {
    return null;
  }

  return (
    <Group
      onUpdate={(data) => onGroupPathsUpdate('insurance', 'task', data)}
      status={facetStatus}
      statusVariant={facetVariant}
      title="Insurance">
      {tasks.map((task) => {
        const taskStatus = isTaskReviewed(task, project)
          ? TASK_STATUSES.COMPLETED
          : task.status;
        const [title, details] = getTabTitleDetails({...task, type: 'task'});
        const basePath = `/viewer-v2/${projectId}/task/${task.id}`;
        const qString = getQueryStringByTaskType(task);
        const pathTo = qString ? `${basePath}?${qString}` : basePath;

        return (
          <Tab
            active={task.id === itemId}
            details={details}
            title={title}
            status={taskStatus}
            key={task.id}
            path={pathTo}
          />
        );
      })}
    </Group>
  );
};

export default InsuranceGroup;
