import React, {Fragment, useState} from 'react';

import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useAcceptTaskDocument, useUpdateMessage} from 'api';

import {Box, Icon, Tooltip} from '@renofi/components-internal';
import {basic55} from '@renofi/theme';
import {useNotifications} from '@renofi/utilities/src/hooks';
import {validateUuidProp} from '@renofi/utilities/src/react';
import {GET_PROJECT, GET_PROJECT_TASKS} from '@renofi/graphql';

import {Approve, Discard} from '../../../../icons';

function TaskItemActions({
  id,
  status: documentStatus,
  rejectionDetails,
  taskType,
}) {
  const {projectId} = useParams();
  const {addNotification} = useNotifications();
  const updateMessage = useUpdateMessage();
  const {onAcceptDocument} = useAcceptTaskDocument({
    documentId: id,
    refetchQueries: [
      {
        query: GET_PROJECT,
        variables: {id: projectId},
      },
      {
        query: GET_PROJECT_TASKS,
        variables: {projectId},
      },
    ],
    taskType,
  });
  const [status, setStatus] = useState(documentStatus);

  const isRejected = status === 'rejected';
  const showRejectionDetails = isRejected && Boolean(rejectionDetails);

  const onClickApprove = async (e) => {
    setStatus('accepted');
    e.preventDefault();
    e.stopPropagation();

    try {
      const {errors} = await onAcceptDocument(id);

      if (errors instanceof Error) {
        throw new Error(errors?.graphQLErrors[0]?.message);
      }
    } catch (err) {
      addNotification({
        id: 'failedToAcceptDocument',
        variant: 'danger',
        type: 'snackbar',
        content: err?.message,
      });
    }
  };

  const onClickReject = (e) => {
    setStatus('rejected');
    e.stopPropagation();
    e.preventDefault();
    updateMessage({
      type: 'documents',
      active: true,
      reason: 'return',
      documents: [id],
    });
  };

  return (
    <Fragment>
      {showRejectionDetails && (
        <Box height={24} ml={16}>
          <Tooltip
            delayHide={500}
            css={{
              padding: 12,
              maxWidth: 300,
              textAlign: 'left',
              zIndex: '1011 !important',
            }}
            content={rejectionDetails}>
            <div css={{cursor: 'pointer'}}>
              <Icon name="comment" color={basic55} />
            </div>
          </Tooltip>
        </Box>
      )}
      <Box height={24} ml={16}>
        <Discard active={status === 'rejected'} onClick={onClickReject} />
      </Box>
      <Box height={24} ml={16}>
        <Approve active={status === 'accepted'} onClick={onClickApprove} />
      </Box>
    </Fragment>
  );
}

TaskItemActions.propTypes = {
  id: validateUuidProp,
  rejectionDetails: PropTypes.string,
  status: PropTypes.string,
  taskId: PropTypes.string.isRequired,
  taskType: PropTypes.string,
};

export default TaskItemActions;
