import {TaskItemActions} from '../../Layout';
import TabContent from '../components/TabContent';

import getTaskDocumentsByPropertyId from './getTaskDocumentsByPropertyId';

export default ({search, task}) => {
  const {propertyId = 'subject_property'} = search || {};
  const documents = getTaskDocumentsByPropertyId({propertyId, task});

  return documents?.map((doc) => {
    return {
      label: (
        <TabContent
          extra={
            <TaskItemActions
              {...doc}
              taskId={task?.id}
              taskType={task?.taskType}
            />
          }
          title={doc.fileName}
        />
      ),
      value: doc.id,
    };
  });
};
