import React, {Fragment, useEffect, useRef, useState} from 'react';

import PropTypes from 'prop-types';
import {find, last, propEq} from 'ramda';
import {useParams} from 'react-router-dom';
import {withViewerLayout} from 'api/hoc';
import {isSplitWideLayout} from 'lib/layoutHelper';
import {Container, RequiredText, getPercentageChange} from 'modules/viewer-v2';
import {OrderAppraisalButton} from 'modules/order-appraisal';
import Col from 'modules/viewer-v2/components/Col';
import Row from 'modules/viewer-v2/components/Row';

import useDebounce from '@renofi/utilities/src/useDebounce';
import {
  useAppraisalOrders,
  useProject,
  useUpdateProject,
} from '@renofi/graphql';
import DollarIcon from '@renofi/icons/src/DollarCircleInvert';
import {
  Box,
  ButtonGroup,
  DateField,
  TextField,
  Flex,
  Text,
} from '@renofi/components-internal';

import {SUBMITTABLE_STATUSES} from '../../../order-appraisal/constants';

import {StickyFooter, FooterNote} from './styled';
import {NO_VALUE} from './constants';
import BorrowerAppraisalValues from './components/BorrowerAppraisalValues';

const getRoi = (afterRenoValue, homeValue, renoCost) => {
  if (!afterRenoValue || !homeValue || !renoCost) {
    return NO_VALUE;
  }

  return getPercentageChange(afterRenoValue - homeValue, renoCost);
};

const haveValuesChanged = (original, toCompare) =>
  Object.keys(toCompare).some((key) => toCompare[key] !== original[key]);

function Appraisal({layout}) {
  const ref = useRef(null);
  const {projectId} = useParams();
  const {project} = useProject({variables: {id: projectId}});
  const {appraisalOrders} = useAppraisalOrders({variables: {projectId}});
  const {updateProject, loading: isUpdating} = useUpdateProject();
  const updateAppraiserValues = useDebounce(updateProject, 500);

  const [currentAppraisalOrder, setCurrentAppraisalOrder] = useState(null);
  const [recentTransactions, setRecentTransactions] = useState(null);

  const [appraiserAsIsValue, setAppraiserAsIsValue] = useState(
    project.appraiserAsIsValue,
  );
  const [appraiserAfterRenovationValue, setAppraiserAfterRenovationValue] =
    useState(project.appraiserAfterRenovationValue);
  const [appraiserName, setAppraiserName] = useState(project.appraiserName);

  const isWideLayout = isSplitWideLayout(layout);
  const {avmValue, status} = currentAppraisalOrder || {};
  const isAppraisalCompleted = status === 'completed';
  const {estimatedRenovationHomeValue, renovationCost, statedCurrentHomeValue} =
    project;
  const {
    createdAt,
    occurredRecently,
    value: appraiserValue,
  } = recentTransactions || {};
  const isRefinanced = occurredRecently;
  const hasActiveOrder = appraisalOrders.some(
    ({status}) => !SUBMITTABLE_STATUSES.includes(status),
  );
  const canSubmitOrder = !appraisalOrders.length || !hasActiveOrder;

  useEffect(() => {
    setCurrentAppraisalOrder(last(appraisalOrders));
  }, [appraisalOrders]);

  useEffect(() => {
    const refinanceTask = find(
      propEq('taskType', 'appraisal_recent_transactions'),
    )(project.tasks);
    if (!refinanceTask) {
      return;
    }

    setRecentTransactions(refinanceTask.appraisalRecentTransactions);
  }, [project.tasks]);

  useEffect(() => {
    const toCompare = {
      appraiserAsIsValue,
      appraiserAfterRenovationValue,
      appraiserName,
    };

    if (haveValuesChanged(project, toCompare)) {
      updateAppraiserValues({
        variables: {
          attributes: {
            appraiserAsIsValue,
            appraiserAfterRenovationValue,
            appraiserName,
          },
          id: project.id,
        },
      });
    }
  }, [appraiserAsIsValue, appraiserAfterRenovationValue, appraiserName]);

  return (
    <div ref={ref}>
      <Container
        header={
          <Flex alignItems="center">
            <Box height={24}>
              <DollarIcon />
            </Box>
            <Text ml={12}>Appraisal</Text>
          </Flex>
        }>
        {isAppraisalCompleted && (
          <Row>
            <Col>
              <TextField
                disabled={isUpdating}
                help={
                  <RequiredText show={!appraiserName}>required</RequiredText>
                }
                label="Appraiser"
                onChange={setAppraiserName}
                value={appraiserName}
              />
            </Col>
          </Row>
        )}

        <BorrowerAppraisalValues
          appraisalValue={appraiserAsIsValue}
          borrowerValue={statedCurrentHomeValue}
          disabled={isUpdating}
          isAppraisalCompleted={isAppraisalCompleted}
          onChange={setAppraiserAsIsValue}
          roi={getRoi(
            appraiserAsIsValue,
            statedCurrentHomeValue,
            renovationCost,
          )}
          title="As-is value"
          esimated
        />

        <Row>
          <Col>
            <TextField
              money
              value={avmValue || NO_VALUE}
              leftIcon="dollar"
              label="AVM value"
            />
          </Col>
        </Row>

        <BorrowerAppraisalValues
          appraisalValue={appraiserAfterRenovationValue}
          borrowerValue={estimatedRenovationHomeValue}
          disabled={isUpdating}
          isAppraisalCompleted={isAppraisalCompleted}
          onChange={setAppraiserAfterRenovationValue}
          roi={getRoi(
            appraiserAfterRenovationValue,
            estimatedRenovationHomeValue,
            renovationCost,
          )}
          title="After renovation value"
        />

        {recentTransactions && (
          <Fragment>
            <Row title="Property purchased or refinanced in the last 12 months">
              <Col>
                <ButtonGroup mt={2} disabled value={isRefinanced} />
              </Col>
            </Row>
            <Row>
              <Col>
                <DateField
                  value={new Date(createdAt)}
                  label="Date of appraisal"
                />
              </Col>
              <Col>
                {appraiserValue && (
                  <TextField
                    label="Appraisal value at the time"
                    leftIcon="dollar"
                    value={appraiserValue}
                  />
                )}
              </Col>
            </Row>
          </Fragment>
        )}
      </Container>

      {canSubmitOrder && (
        <StickyFooter p={3}>
          <FooterNote>
            Review all documents/info before ordering an appraisal
          </FooterNote>

          <Col
            css={{
              textAlign: isWideLayout ? 'right' : 'left',
              marginTop: isWideLayout ? 0 : 16,
            }}
            ml="auto"
            justifyContent="flex-end">
            <OrderAppraisalButton />
          </Col>
        </StickyFooter>
      )}
    </div>
  );
}

Appraisal.propTypes = {
  layout: PropTypes.string,
  project: PropTypes.shape({
    appraiserAsIsValue: PropTypes.number,
    appraiserAfterRenovationValue: PropTypes.number,
    appraiserName: PropTypes.string,
    estimatedRenovationHomeValue: PropTypes.number,
    id: PropTypes.string.isRequired,
    renovationCost: PropTypes.number,
    statedCurrentHomeValue: PropTypes.number,
    tasks: PropTypes.arrayOf(
      PropTypes.shape({
        appraisalRecentTransactions: PropTypes.shape({
          createdAt: PropTypes.string,
          occurredRecently: PropTypes.bool,
          value: PropTypes.number,
        }),
        taskType: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default withViewerLayout()(Appraisal);
