import {propEq, filter, isEmpty} from 'ramda';
import TASK_STATUSES from 'lib/task/taskStatuses';

import {isTaskReviewed} from '../../../utils';
import {
  FEASIBILITY_REVIEW_STATUSES,
  FEASIBILITY_REVIEW_NAVBAR_PROPERTIES_BY_STATUSES,
} from '../constants';

export const getVariantStatusForTasks = (tasks = []) => {
  const isTaskCompleted = tasks.every(
    propEq('status', TASK_STATUSES.COMPLETED),
  );
  const isReviewed = tasks.every(isTaskReviewed);

  if (isTaskCompleted) {
    return ['successInverse', 'Completed'];
  }

  if (isReviewed) {
    return ['success', 'Reviewed'];
  }

  return ['warning', 'Pending review'];
};

function getFeasibilityReviewNavbarProperties(status) {
  return FEASIBILITY_REVIEW_NAVBAR_PROPERTIES_BY_STATUSES[status];
}

export function getFeasibilityStatus({
  feasibilityReview,
  feasibilityReviews = [],
  tasks,
}) {
  const otherFeasibilityReviews = feasibilityReviews.filter(
    (review) => review.id !== feasibilityReview.id,
  );
  const hasOtherFeasibilityReview = otherFeasibilityReviews.length > 0;
  const hasOtherCompletedFeasibilityReview = otherFeasibilityReviews.some(
    propEq('status', FEASIBILITY_REVIEW_STATUSES.completed),
  );

  if (hasOtherCompletedFeasibilityReview) {
    return ['danger', 'Rejected'];
  }

  const feasibilityReviewIndex = feasibilityReviews.findIndex(
    propEq('id', feasibilityReview.id),
  );
  const isFirstFeasibilityReview = feasibilityReviewIndex === 0;
  const orderLabel = hasOtherFeasibilityReview
    ? `${isFirstFeasibilityReview ? '1st' : '2nd'} analysis `
    : '';

  const navbarProperties = getFeasibilityReviewNavbarProperties(
    feasibilityReview.status,
  );

  if (navbarProperties) {
    const displayLabel = `${orderLabel}${navbarProperties.label}`;

    return [navbarProperties.color, displayLabel];
  }

  const isReviewed = tasks.every(
    ({documents = []}) =>
      !isEmpty(documents) && documents.every(propEq('status', 'completed')),
  );

  if (isReviewed) {
    return ['success', 'Reviewed'];
  }

  return ['warning', 'Pending review'];
}

export const getFacetStatus = (items) => {
  const tasks = items.filter(propEq('type', 'task'));
  const isTaskCompleted = tasks.every(
    propEq('status', TASK_STATUSES.COMPLETED),
  );
  const isReviewed = tasks.every(isTaskReviewed);

  if (isTaskCompleted) {
    return ['successInverse', 'Completed'];
  }

  if (isReviewed) {
    return ['success', 'Reviewed'];
  }

  return ['warning', 'Pending review'];
};

export const getGcddReviewStatus = (isGcddReviewCompleted) => {
  if (isGcddReviewCompleted) {
    return ['successInverse', 'Completed'];
  }

  return ['warning', 'Pending review'];
};

export const getInsuranceReviewStatus = ({items, insuranceReview}) => {
  const tasks = filter(propEq('type', 'task'), items);

  const allDocumentsAccepted = tasks.every(
    propEq('allDocumentsAccepted', true),
  );
  const allTasksComplete = tasks.every(
    propEq('status', TASK_STATUSES.COMPLETED),
  );
  const isReviewCompleted = Boolean(insuranceReview?.completedAt);

  const complete =
    allDocumentsAccepted && allTasksComplete && isReviewCompleted;

  let variant = complete ? 'successInverse' : 'warning';
  let status = complete ? 'completed' : 'Pending review';

  return [variant, status];
};
