import {find, propEq, propOr} from 'ramda';

import getPropertiesByTask from './getPropertiesByTask';

export default ({propertyId, task}) => {
  const properties = getPropertiesByTask(task);
  const property = find(propEq('id', propertyId), properties);
  const documentIds = propOr([], 'documentIds', property);

  return task?.documents.filter(({id}) => documentIds.includes(id));
};
