import {gql} from '@apollo/client';

import {contractor, applicant} from '@renofi/graphql/src/partials';

const DOCUMENT = `{
  contentType
  createdAt
  documentType
  id
  fileName
  fileSizeBytes
  objectName
  pdfFileName
  pdfObjectName
  rejectedAt
  reviewedAt
  sharedWithLenderAt
  shareWithLenderAllowed
  shareable
  source
  status
  systemGenerated
  updatedAt
}`;

export default gql`
  query projectDocuments($id: ID!) {
    projectById(id: $id) {
      id
      borrower {
        email
        firstName
        id
        lastName
        phoneNumber
      }
      primaryApplicant {
        ${applicant}
      }
      documents ${DOCUMENT}
      archivedDocuments ${DOCUMENT}
      tasks {
        contractorInformations {
          ${contractor}
        }
        documents ${DOCUMENT}
        id
        taskType
      }
    }
  }
`;
