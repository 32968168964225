import {initDataDog} from '@renofi/analytics';
import isImpersonation from '@renofi/utilities/src/isImpersonation';
import {initLogRocket} from '@renofi/utilities/src/logrocket2';
import initAnalytics from '@renofi/utilities/src/segment';
import {initSentry} from '@renofi/utilities/src/analytics';

export default async ({config, storage}) => {
  const {
    DEVELOPMENT,
    PRODUCTION,
    SEGMENT_KEY: segmentKey,
    SEGMENT_URL: baseUrl,
    LOGROCKET_ID,
  } = config;
  const impersonation = isImpersonation(storage);

  if (impersonation) return;

  if (!DEVELOPMENT && segmentKey) {
    initAnalytics({
      segmentKey,
      baseUrl,
      options: {integrations: {All: false, Heap: true}},
    });
  }

  if (PRODUCTION) {
    initLogRocket(LOGROCKET_ID);
    initSentry();
  }
  initDataDog({
    envs: ['staging', 'production'],
    ignoreErrors: ['csp_violation'],
  });
};
