import React from 'react';

import PropTypes from 'prop-types';
import {
  EMPLOYMENT_HISTORY_TASK_TYPE,
  REAL_ESTATE_TASK_TYPE,
} from 'modules/viewer-v2';

import {Box, Flex} from '@renofi/components-internal';

import {getBadgeIconColor} from '../../utils';

import {Badge} from './styled';

const ItemsFilterBadge = ({children, icon: Icon, task, value, ...props}) => {
  const {taskType, status} = task || {};
  const color = getBadgeIconColor(status);
  const hasIcon = Boolean(Icon);

  const onClick = () => {
    switch (true) {
      case taskType === REAL_ESTATE_TASK_TYPE:
        return props.onClick('propertyId', value);
      case taskType === EMPLOYMENT_HISTORY_TASK_TYPE:
        return props.onClick('employmentId', value);
      default:
        props.onClick('borrowerRole', value);
    }
  };

  return (
    <Badge pill {...props} onClick={onClick}>
      <Flex alignItems="center">
        {hasIcon && <Icon color={color} />}

        <Box ml={hasIcon ? 2 : 0} as="span">
          {children}
        </Box>
      </Flex>
    </Badge>
  );
};

ItemsFilterBadge.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.any,
  onClick: PropTypes.func.isRequired,
  task: PropTypes.shape({
    status: PropTypes.string.isRequired,
    taskType: PropTypes.string.isRequired,
  }),
  value: PropTypes.string,
};

export default ItemsFilterBadge;
