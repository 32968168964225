import {propOr} from 'ramda';

import getPropertiesByTask from './getPropertiesByTask';

export default ({documentId, task}) => {
  const properties = getPropertiesByTask(task);

  return properties.find((p) => {
    const documentIds = propOr([], 'documentIds', p);
    return documentIds.includes(documentId);
  });
};
