import React, {useContext} from 'react';

import PropTypes from 'prop-types';

import {Arrow} from '@renofi/icons';

import ViewerV2Context from '../../../../context';

import {InnerWrapper, ItemContent, Wrapper, PrevNextBtn} from './styled';

const ItemView = ({
  children,
  itemKey = 'documentId',
  showNext = true,
  showPrev = true,
}) => {
  const {onClickPrevNext} = useContext(ViewerV2Context);

  return (
    <Wrapper>
      <InnerWrapper>
        <ItemContent>{children}</ItemContent>
      </InnerWrapper>

      {showPrev && (
        <PrevNextBtn
          prev
          active
          onClick={() => onClickPrevNext({itemKey, forward: false})}>
          <Arrow direction="left" width={24} height={24} />
        </PrevNextBtn>
      )}

      {showNext && (
        <PrevNextBtn
          active
          onClick={() => onClickPrevNext({itemKey, forward: true})}>
          <Arrow direction="right" width={24} height={24} />
        </PrevNextBtn>
      )}
    </Wrapper>
  );
};

ItemView.propTypes = {
  children: PropTypes.node,
  itemKey: PropTypes.string,
  showNext: PropTypes.bool,
  showPrev: PropTypes.bool,
};

export default ItemView;
