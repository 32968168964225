import {propOr} from 'ramda';

import {GET_LOAN_PRODUCTS} from '../../queries';
import useToggledQuery from '../useToggledQuery';

export default function useLoanProducts({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_LOAN_PRODUCTS,
    options: {
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch} = response;
  const loanProducts = propOr(null, 'loanProducts', data);

  return {error, fetch, loanProducts, loading};
}
