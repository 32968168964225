import React from 'react';

import PropTypes from 'prop-types';
import {ItemsBarTabFileName} from 'modules/viewer-v2/components/Layout';

import {Flex} from '@renofi/components-internal';

import Actions from '../../AppraisalReportDocumentActions';

const TabContent = ({appraisalOrders, appraisalReport}) => {
  return (
    <Flex justifyContent="space-between" width={1}>
      <Flex flexDirection="column" maxWidth={180}>
        <ItemsBarTabFileName>{appraisalReport.fileName}</ItemsBarTabFileName>
      </Flex>
      <Flex alignItems="center" flexShrink={0}>
        <Actions
          appraisalOrders={appraisalOrders}
          appraisalReport={appraisalReport}
        />
      </Flex>
    </Flex>
  );
};

TabContent.propTypes = {
  appraisalOrders: PropTypes.array,
  appraisalReport: PropTypes.shape({
    fileName: PropTypes.string,
  }),
};

export default TabContent;
