import {useLocation, useParams} from 'react-router-dom';
import {find, pipe, propEq, propOr} from 'ramda';

import {branch, compose, withContext, withProps} from '@renofi/recompose/src';
import {useProject} from '@renofi/graphql';
import withPathParams from '@renofi/utilities/src/withPathParams';
import spreadProps from '@renofi/utilities/src/spreadProps';

/**
 * @description Branching to handle case where ViewerV2 is used
 * @todo can kill it off when we decouple from HOC completely.
 */
export default (ViewerContext) =>
  compose(
    branch(
      () => {
        const {pathname} = useLocation();
        const isV2Viewer = pathname.includes('viewer-v2');
        return isV2Viewer;
      },
      withProps(() => {
        const {itemId, projectId} = useParams();
        const {project} = useProject({variables: {id: projectId}});
        const task = project?.tasks?.find(({id}) => id === itemId);
        return {
          facet: task?.facet,
          task,
          taskId: itemId,
        };
      }),
      compose(
        withPathParams(['facet', 'taskId']),
        spreadProps(['params']),
        withContext(ViewerContext),
        withProps(({facet, facets, taskId}) => ({
          task: pipe(
            find(propEq('value', facet)),
            propOr([], 'items'),
            find(propEq('id', taskId)),
          )(facets),
        })),
      ),
    ),
  );
