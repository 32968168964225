import styled from '@emotion/styled';

import {basic55, basic75, basic88} from '@renofi/theme/src/colors';

import Col from '../Col';
import Row from '../Row';

export const StickyFooter = styled(Row)({
  background: basic88,
  borderTop: `1px solid ${basic75}`,
  boxShadow: '0 -2px 7px rgba(0, 0, 0, 0.2)',
  position: 'sticky',
  bottom: 0,
});

export const FooterNote = styled(Col)({
  color: basic55,
});
