import React, {useCallback, useMemo, useState} from 'react';

import {Route, Switch, useRouteMatch} from 'react-router-dom';
import {head, indexOf, isNil, keys, last, propOr} from 'ramda';
import MessageBorrower from 'modules/message';
import {ModalsContainer} from 'modules/modals';

import {Provider as ThemeProvider} from '@renofi/theme/src';
import {useGetUser} from '@renofi/graphql';
import queryString from '@renofi/utilities/src/queryString';

import AppraisalReportType from './components/AppraisalReportType';
import FeasibilityReviewType from './components/FeasibilityReviewType';
import GcddReviewType from './components/GcddReviewType';
import GlobalNavBar from './components/GlobalNavBar';
import TaskType from './components/TaskType';
import ViewerV2Context from './context';
import {VIEWER_DATA_TYPES} from './constants';
import {Wrapper} from './styled';
import {useViewerNavigation} from './hooks';
import {getAllowedFacetTypesByUser, getPathByNextPrevItemType} from './utils';

const DATA_TYPE_COMPONENTS = {
  task: TaskType,
  appraisal_report: AppraisalReportType,
  feasibility_review: FeasibilityReviewType,
  gcdd_review: GcddReviewType,
};

const ViewerV2 = () => {
  const [pathsByGroup, setPathsByGroup] = useState({});
  const [activePath, setActivePath] = useState(null);
  const [itemsFilterCache, setItemsFilterCache] = useState([]);
  const [itemsBarCache, setItemsBarCache] = useState([]);
  const [isLeftToRight, setIsLeftToRight] = useState(true);
  const {history, search} = useViewerNavigation();
  const {path} = useRouteMatch();
  const {user} = useGetUser();

  const allowedGroups = useMemo(
    () => getAllowedFacetTypesByUser(user),
    [user?.permissions],
  );
  const allGroupPaths = useMemo(() => {
    const groupKeys = keys(pathsByGroup);
    return allowedGroups.reduce((arr, key) => {
      const groups = groupKeys.filter((k) => key === k.split(':')[0]);
      if (!groups.length) {
        return arr;
      }
      return arr.concat(
        ...groups.map((groupKey) => pathsByGroup[groupKey]?.[1]),
      );
    }, []);
  }, [allowedGroups, pathsByGroup]);
  const totalGroupPaths = allGroupPaths?.length || 0;

  const getQueryString = (data) => queryString.stringify({...search, ...data});

  const getItemIdByDirection = (items = [], prop = 'id') => {
    const getter = isLeftToRight ? head : last;
    const item = getter(items);

    return propOr(null, prop, item);
  };

  const onClickPrevNext = ({itemKey, forward = true}) => {
    const hasOneTab = itemsBarCache.length === 1;
    const currentIndex = hasOneTab
      ? 0
      : indexOf(search[itemKey], itemsBarCache);
    const isFirstItem = currentIndex === 0;
    const isLastItem = currentIndex === itemsBarCache.length - 1;
    const newDocId = itemsBarCache[currentIndex + (forward ? 1 : -1)];
    let url;

    setIsLeftToRight(forward);
    switch (true) {
      // First item and go backwards
      case !forward && isFirstItem:
      case !forward && !newDocId:
      case forward && isLastItem:
      case forward && !newDocId:
        // case backwards + no new items
        url = getPathByNextPrevItemType({
          activePath,
          allGroupPaths,
          forward,
          itemsFilterCache,
          search,
        });

        return url ? history.push(url) : null;
      default:
        return history.push(`?${getQueryString({[itemKey]: newDocId})}`);
    }
  };

  const onGroupPathsUpdate = useCallback(
    (key, dataType, data = []) => {
      // Must be function callback for setter to avoid race conditions
      setPathsByGroup((prev) => ({
        ...prev,
        [key]: [dataType, data],
      }));
    },
    [pathsByGroup],
  );

  const onItemsFilterIdsUpdate = (key, data = []) => {
    if (isNil(key) || !data?.length) {
      return setItemsFilterCache([]);
    }
    setItemsFilterCache([key, data]);
  };

  return (
    <ThemeProvider theme="dark">
      <ViewerV2Context.Provider
        value={{
          allowedGroups,
          allGroupPaths,
          getItemIdByDirection,
          isLeftToRight,
          onClickPrevNext,
          onGroupPathsUpdate,
          onItemsFilterIdsUpdate,
          setItemsBarCache,
          setActivePath,
          setIsLeftToRight,
          itemsFilterCache,
          totalGroupPaths,
        }}>
        <Wrapper>
          <ModalsContainer>
            <GlobalNavBar />
            <Switch>
              {VIEWER_DATA_TYPES.map((dataType) => {
                const Component = DATA_TYPE_COMPONENTS[dataType];
                return Component ? (
                  <Route
                    key={dataType}
                    path={`${path}/${dataType}/:itemId`}
                    component={Component}
                  />
                ) : null;
              })}
            </Switch>
          </ModalsContainer>
        </Wrapper>
        <MessageBorrower />
      </ViewerV2Context.Provider>
    </ThemeProvider>
  );
};

export default ViewerV2;
