import React, {useContext, useEffect, useMemo} from 'react';

import {find, map, pathOr, pipe, prop, propEq, propOr, sortBy} from 'ramda';
import {
  useTaskByCurrentItemId,
  useViewerNavigation,
} from 'modules/viewer-v2/hooks';

import {Flex} from '@renofi/components-internal';
import {Company} from '@renofi/icons';
import {basic80} from '@renofi/theme';
import queryString from '@renofi/utilities/src/queryString';

import ViewerV2Context from '../../../context';

import ItemsFilterBadge from './ItemsFilterBadge/ItemsFilterBadge';

const getSortedHistory = pipe(
  propOr([], 'employmentHistories'),
  sortBy(prop('coborrowerEmploymentHistory')),
);

const EmploymentTabs = () => {
  const {onItemsFilterIdsUpdate} = useContext(ViewerV2Context);
  const {task} = useTaskByCurrentItemId();
  const {history, search} = useViewerNavigation();
  const {employmentId} = search || {};
  const employmentHistories = getSortedHistory(task || {});

  const onClickTab = (key, value) => {
    const employment = find(propEq('id', value), employmentHistories);
    const documents = employment?.documents || [];
    const documentId = pathOr(undefined, [0, 'id'], documents);

    const to = queryString.stringify({
      ...search,
      documentId,
      [key]: value,
    });

    return history.push(`?${to}`);
  };

  const tabs = useMemo(() => {
    return getSortedHistory(task || {}).map((employment) => {
      const {coborrowerEmploymentHistory, currentEmployment, employerName, id} =
        employment;
      const current = currentEmployment ? '(current)' : '';
      const borrower = coborrowerEmploymentHistory ? '(co-borrower)' : '';
      const label = coborrowerEmploymentHistory
        ? [employerName, current, borrower].join(' ')
        : [employerName, current].join(' ');

      return {
        label,
        value: id,
      };
    });
  }, [JSON.stringify(task)]);

  useEffect(() => {
    onItemsFilterIdsUpdate('employmentId', map(prop('value'), tabs));
    if (!search.employmentId && Boolean(tabs?.length)) {
      const to = queryString.stringify({
        ...search,
        employmentId: tabs[0]?.value,
      });

      history.replace(`?${to}`);
    }

    return () => {
      onItemsFilterIdsUpdate();
    };
  }, [search, tabs.length]);

  return (
    <Flex px={2} py={3} backgroundColor={basic80}>
      {tabs.map(({label, value}) => {
        return (
          <ItemsFilterBadge
            active={employmentId === value}
            icon={Company}
            key={value}
            onClick={onClickTab}
            task={task}
            value={value}>
            {label}
          </ItemsFilterBadge>
        );
      })}
    </Flex>
  );
};

export default EmploymentTabs;
