import {head, pathOr, pipe, propEq, propOr, reject} from 'ramda';
import {
  EMPLOYMENT_HISTORY_TASK_TYPE,
  REAL_ESTATE_TASK_TYPE,
} from 'modules/viewer-v2';
import {COBORROWER_TASK_TYPES} from 'modules/viewer-v2/constants';

import {filterItemsByBorrowerRole} from '@renofi/utilities/src/coborrower';
import queryString from '@renofi/utilities/src/queryString';

const getFirstDocId = pathOr(undefined, [0, 'id']);
const PROPERRTY_DOC_IDS = ['subjectProperty', 'documentIds'];

const getFirstEmploymentId = pipe(
  propOr([], 'employmentHistories'),
  reject(propEq('coborrowerEmploymentHistory', true)),
  head,
  propOr(undefined, 'id'),
);

const getFirstDocumentByTaskType = ({
  canHaveDocument,
  documents = [],
  realEstateOwned,
  taskType,
} = {}) => {
  const hasRealEstate = taskType === REAL_ESTATE_TASK_TYPE;
  const hasCoborrower = COBORROWER_TASK_TYPES.includes(taskType);

  switch (true) {
    case hasCoborrower:
      return getFirstDocId(filterItemsByBorrowerRole(documents));
    case hasRealEstate:
      const documentIds = pathOr([], PROPERRTY_DOC_IDS, realEstateOwned);
      return getFirstDocId(
        documents.filter(({id}) => documentIds.includes(id)),
      );
    case canHaveDocument:
    default:
      return getFirstDocId(documents);
  }
};

export const getQueryStringByTaskType = (task = {}) => {
  const {canHaveDocument, realEstateOwned, taskType} = task;
  const isEmploymentTask = taskType === EMPLOYMENT_HISTORY_TASK_TYPE;
  const isRealEstateTask = taskType === REAL_ESTATE_TASK_TYPE;
  const hasCoborrower = COBORROWER_TASK_TYPES.includes(taskType);
  const documentId = getFirstDocumentByTaskType(task);
  const subjectPropertyId = realEstateOwned?.subjectProperty?.id;

  switch (true) {
    case isRealEstateTask:
      return queryString.stringify({
        propertyId: subjectPropertyId,
        documentId,
      });
    case isEmploymentTask:
      const employmentId = getFirstEmploymentId(task);
      return queryString.stringify({borrowerRole: 'borrower', employmentId});
    case hasCoborrower:
      return queryString.stringify({borrowerRole: 'borrower', documentId});
    case canHaveDocument:
    default:
      return queryString.stringify({documentId});
  }
};
