import React, {Fragment} from 'react';

import PropTypes from 'prop-types';
import {isEmpty} from 'ramda';

import {Box, Text} from '@renofi/components-internal';
import {basic20} from '@renofi/theme/src/colors';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';

import {List, ListItem} from './styled';

function ValidationTooltipContent({invalidFields}) {
  const hasInvalidFields = !isEmpty(invalidFields);

  if (isEmpty(invalidFields)) {
    return null;
  }

  return (
    <Box
      width={400}
      css={{textAlign: 'left', fontSize: 14, lineHeight: '22px'}}>
      {hasInvalidFields && (
        <Fragment>
          <Text color={basic20} mb={16}>
            The following fields are invalid / empty:
          </Text>
          <List>
            {invalidFields.map((invalidField) => {
              return (
                <ListItem key={invalidField}>
                  {humanizeSnakeCase(invalidField)}
                </ListItem>
              );
            })}
          </List>
        </Fragment>
      )}
    </Box>
  );
}

ValidationTooltipContent.propTypes = {
  invalidFields: PropTypes.arrayOf(PropTypes.string),
};

export default ValidationTooltipContent;
