import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import {UPDATE_NOTE} from '../../mutations';
import {GET_PROJECT_NOTES} from '../../queries';
import useMutation from '../useMutation';

export default function useUpdateNote({
  onCompleted = noop,
  refetchQueries = [GET_PROJECT_NOTES],
} = {}) {
  const [updateNote, {data, loading, error}] = useMutation(UPDATE_NOTE, {
    onCompleted,
    refetchQueries,
  });
  const response = pathOr(null, ['updateNote'], data);
  return {updateNote, response, loading, error};
}
