import taskStatuses from 'lib/task/taskStatuses';

import {
  dangerLight,
  successLight,
  warningRegular,
} from '@renofi/theme/src/colors';

export {default as getItemKeyByTask} from './getItemKeyByTask';
export {default as getPropertiesByTask} from './getPropertiesByTask';
export {default as getPropertyByDocumentId} from './getPropertyByDocumentId';
export {default as getTabsFromEmployment} from './getTabsFromEmployment';
export {default as getTabsFromRealEstate} from './getTabsFromRealEstate';
export {default as getTabsFromTaskDocuments} from './getTabsFromTaskDocuments';
export {default as getTaskDocumentsByPropertyId} from './getTaskDocumentsByPropertyId';

export const getBadgeIconColor = (status) => {
  if (status === taskStatuses.PROCESSING) {
    return warningRegular;
  }
  if (status === taskStatuses.COMPLETED) {
    return successLight;
  }
  return dangerLight;
};
