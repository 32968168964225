import React from 'react';

import './index.css';
import * as appConfig from 'config';
import Root from 'modules/root';
import FeasibilityReviewer from 'modules/feasibility-reviewer';

import {initializeApp} from '@renofi/modules-internal';
import polyfills from '@renofi/utilities/src/polyfills';
import {getLocation} from '@renofi/utilities/src/window';

import writeInitialData from './initialData';
import initAnalytics from './analytics';

const {firstRound, secondRound} = polyfills();

const {BLUEPRINT_SERVICE_ACCOUNT_ID, REVIEWERS_SERVICE_ACCOUNT_ID} = appConfig;

const {hostname} = getLocation();
const isReviewers =
  hostname.includes('reviewers') ||
  new URLSearchParams(window.location.search).get('reviewers');
const serviceAccountId = isReviewers
  ? REVIEWERS_SERVICE_ACCOUNT_ID
  : BLUEPRINT_SERVICE_ACCOUNT_ID;

const init = () => {
  initializeApp({
    appConfig: {
      ...appConfig,
      SERVICE_ACCOUNT_ID: serviceAccountId,
    },
    apolloClientCache: {
      typePolicies: {
        FeasibilityReview: {
          fields: {
            termsOfPayments: {
              merge(existing, incoming) {
                return incoming;
              },
            },
          },
        },
        FeasibilityReviewState: {
          fields: {
            termsOfPayments: {
              merge(existing, incoming) {
                return incoming;
              },
            },
          },
        },
      },
    },
    onInit: async ({config, client, storage}) => {
      writeInitialData({client, isReviewers, storage});
      await initAnalytics({config, storage});
    },
    rootComponent: isReviewers ? <FeasibilityReviewer /> : <Root />,
  });
};

Promise.all(firstRound)
  .then(() => Promise.all(secondRound))
  .then(() => init())
  .catch((error) => {
    console.error('Failed fetching polyfills', error);
  });
