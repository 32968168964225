import React, {useContext, useEffect, useMemo} from 'react';

import {map, pathOr, prop} from 'ramda';
import PropTypes from 'prop-types';
import {EMPLOYMENT_HISTORY_TASK_TYPE} from 'modules/viewer-v2';
import {
  useTaskByCurrentItemId,
  useViewerNavigation,
} from 'modules/viewer-v2/hooks';

import {Flex} from '@renofi/components-internal';
import {PersonCircle} from '@renofi/icons';
import {basic80} from '@renofi/theme';
import {
  filterItemsByBorrowerRole,
  hasCoBorrowerInProject,
} from '@renofi/utilities/src/coborrower';
import queryString from '@renofi/utilities/src/queryString';
import {validateUuidProp} from '@renofi/utilities/src/react';

import ViewerV2Context from '../../../context';
import {getTabsFromEmployment} from '../utils';

import ItemsFilterBadge from './ItemsFilterBadge';

const BorrowersTabs = ({task}) => {
  const {onItemsFilterIdsUpdate} = useContext(ViewerV2Context);
  const {project} = useTaskByCurrentItemId();
  const {history, search} = useViewerNavigation();
  const {borrowerRole} = search || {};

  const {canHaveDocument, documents = []} = task;
  const isEmploymentTask = task?.taskType === EMPLOYMENT_HISTORY_TASK_TYPE;
  const hasCoborrower = hasCoBorrowerInProject(project);

  const tabs = useMemo(() => {
    if (!hasCoborrower) {
      return [];
    }

    const coborrowerName = project?.coborrower?.fullName;
    const borrowerName = project?.primaryApplicant?.fullName;
    return [
      {label: borrowerName, value: 'borrower'},
      {label: coborrowerName, value: 'coborrower'},
    ];
  }, [hasCoborrower]);

  useEffect(() => {
    onItemsFilterIdsUpdate('borrowerRole', map(prop('value'), tabs));
    return () => {
      onItemsFilterIdsUpdate();
    };
  }, [tabs.length]);

  const onClickTab = (key, value) => {
    let to;

    switch (true) {
      case isEmploymentTask:
        const histories = getTabsFromEmployment({
          search: {borrowerRole: value},
          task,
        });
        const employmentId = pathOr(undefined, [0, 'value'], histories);
        to = queryString.stringify({
          ...search,
          employmentId,
          [key]: value,
        });
        return history.push(`?${to}`);
      default:
        const docs = canHaveDocument
          ? filterItemsByBorrowerRole(documents, {borrowerRole: value})
          : [];
        const documentId = pathOr(undefined, [0, 'id'], docs);
        to = queryString.stringify({
          ...search,
          documentId,
          [key]: value,
        });
        return history.push(`?${to}`);
    }
  };

  if (!tabs.length) {
    return null;
  }

  return (
    <Flex px={2} py={3} pl={20} backgroundColor={basic80}>
      {tabs.map(({label, value}) => {
        return (
          <ItemsFilterBadge
            active={borrowerRole === value}
            icon={PersonCircle}
            key={value}
            onClick={onClickTab}
            task={task}
            value={value}>
            {label}
          </ItemsFilterBadge>
        );
      })}
    </Flex>
  );
};

BorrowersTabs.propTypes = {
  task: PropTypes.shape({
    canHaveDocument: PropTypes.bool,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        id: validateUuidProp,
      }),
    ),
    taskType: PropTypes.string.isRequired,
  }),
};

export default BorrowersTabs;
