import {filterItemsByBorrowerRole} from '@renofi/utilities/src/coborrower';

import {TaskItemActions} from '../../Layout';
import TabContent from '../components/TabContent';

export default ({search, task} = {}) => {
  const {borrowerRole = 'borrower'} = search || {};
  const documents = filterItemsByBorrowerRole(task, {
    borrowerRole,
    prop: 'documents',
  });

  return documents?.map((doc) => {
    return {
      label: (
        <TabContent
          extra={
            <TaskItemActions
              {...doc}
              taskId={task?.id}
              taskType={task?.taskType}
            />
          }
          subTitle={doc.documentType}
          title={doc.fileName}
        />
      ),
      value: doc.id,
    };
  });
};
