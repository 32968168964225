import {makeVar, useReactiveVar} from '@apollo/client';

const selectedHistoryVar = makeVar(null);

const useSelectedEmploymentHistory = () => {
  const selectedHistory = useReactiveVar(selectedHistoryVar);
  const activeId = selectedHistory?.id;

  const setSelectedHistory = (value) => {
    selectedHistoryVar(value);
  };

  return {activeId, selectedHistory, setSelectedHistory};
};

export default useSelectedEmploymentHistory;
