import styled from '@emotion/styled';

import {TextField} from '@renofi/components-internal';

export const Input = styled(TextField)({
  height: 43,
  borderRadius: 4,
});

export const Icon = styled.div(
  {
    width: 20,
    height: 20,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: 'center center',
  },
  ({icon}) => ({
    backgroundImage: `url('${icon}')`,
  }),
);
