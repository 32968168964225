import React, {Fragment, useState} from 'react';

import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';

import {useAppraisalOrders} from '@renofi/graphql/src/hooks';
import {Button, Loader} from '@renofi/components-internal';

import OrderAppraisalModal from '../OrderAppraisalModal';
import {getCurrentNonFinalInspection} from '../../utils';

function OrderAppraisalButton({buttonVariant}) {
  const {projectId} = useParams();
  const {appraisalOrders, loading: isLoadingAppraisalOrders} =
    useAppraisalOrders({variables: {projectId}});

  const [showModal, setShowModal] = useState(false);

  const currentNonFinalInspectionOrder =
    getCurrentNonFinalInspection(appraisalOrders);

  if (isLoadingAppraisalOrders) {
    return <Loader label={false} width={16} />;
  }

  return (
    <Fragment>
      <Button variant={buttonVariant} onClick={() => setShowModal(true)}>
        Order Appraisal
      </Button>

      {showModal && (
        <OrderAppraisalModal
          onClose={() => setShowModal(false)}
          previousAppraisalOrderId={currentNonFinalInspectionOrder?.id}
        />
      )}
    </Fragment>
  );
}

OrderAppraisalButton.propTypes = {
  buttonVariant: PropTypes.string,
};

export default OrderAppraisalButton;
