import {pathOr} from 'ramda';

import noop from '@renofi/utilities/src/noop';

import {DELETE_NOTE} from '../../mutations';
import {GET_PROJECT_NOTES} from '../../queries';
import useMutation from '../useMutation';

export default function useDeleteNote({
  onCompleted = noop,
  projectId,
  refetchQueries,
} = {}) {
  const [deleteNote, {data, loading, error}] = useMutation(DELETE_NOTE, {
    onCompleted,
    refetchQueries: [
      {
        query: GET_PROJECT_NOTES,
        variables: {id: projectId},
      },
      ...refetchQueries,
    ],
  });
  const response = pathOr(null, ['deleteNote'], data);
  return {deleteNote, response, loading, error};
}
