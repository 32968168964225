import styled from '@emotion/styled';

import {Text} from '@renofi/components-internal';
import {basic55, basic75} from '@renofi/theme/src/colors';

export const InfoText = styled(Text)({
  marginTop: 6,
  marginBottom: 24,
  padding: 12,
  backgroundColor: basic75,
  borderRadius: 4,
  fontSize: 16,
});

export const LabelText = styled(Text)({
  fontSize: 14,
  color: basic55,
});
