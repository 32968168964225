import React, {Fragment} from 'react';

import Appraisal from 'modules/viewer-v2/components/Appraisal';

import {useTaskByCurrentItemId} from '../../hooks';
import {ItemView, DataTypeViewport} from '../Layout';
import Eligibility from '../Eligibility';

import {
  InsuranceReview,
  ItemsBar,
  ItemView as TaskItemView,
  StatusBar,
} from './components';
import {getItemKeyByTask} from './utils';

const TaskType = () => {
  const {project, task} = useTaskByCurrentItemId();
  const projectId = project?.id;
  const facet = task?.facet;
  const itemKey = getItemKeyByTask(task);

  const isEligibility = facet === 'eligibility';
  const isInsurance = facet === 'insurance';
  const isAppraisal = facet === 'appraisal';

  if (!project && !task) {
    return null;
  }

  return (
    <DataTypeViewport
      sidebar={
        <Fragment>
          {isInsurance && <InsuranceReview project={project} />}
          {isEligibility && <Eligibility project={project} />}
          {isAppraisal && <Appraisal projectId={projectId} />}
        </Fragment>
      }
      statusBar={<StatusBar projectId={projectId} task={task} />}>
      <ItemsBar task={task} />
      <ItemView itemKey={itemKey}>
        <TaskItemView task={task} />
      </ItemView>
    </DataTypeViewport>
  );
};

export default TaskType;
